import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { getTranslation, Tr } from '@lsoft/translate';
// noinspection ES6CheckImport
import { MuiConfirmDialog, MuiGlobal } from '@lsoft/mui';
import DialogContentText from '@material-ui/core/DialogContentText';
import { trCancel } from '@lsoft/shared/translations/Address.tr';
import {
	trIfYouSubscribeToOurNewsletter,
	trSubscribeNow,
	trSubscribeToOurNewsletter,
	trWeHaveSendConfirmationLinkToEmail,
	trYouWillReceiveAConfirmationLink,
} from './MailingListSubscription.tr';
import { GTMProviderContext } from '../../container/shop/GTMProvider';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { trSomethingWentWrongPleaseTryAgainLater } from '../CustomerCard/CustomerCardView.tr';
import { marketing_mailing_list_register_post } from '@lsoft/shared/backend/marketing/marketing';

export const MailingListSubscriptionDialog = ({ EMAIL, optin, onClose }) => {
	const { locale } = useRouter();
	// noinspection JSCheckFunctionSignatures
	const gtmProviderContext = useContext(GTMProviderContext);
	const throwSnackbar = useShopSnackbar();

	const mailing_list = MuiGlobal.get('mailingList', null);

	const handleOnConfirm = async () => {
		try {
			if (!_.isNil(gtmProviderContext)) {
				await gtmProviderContext.newsletter_sign_up();
			}
			await marketing_mailing_list_register_post({
				email: EMAIL,
				newsletter_name: mailing_list,
				newsletter_group: 'ltoys',
				language: locale,
				optin: optin,
			});
			throwSnackbar({
				msg: getTranslation(trWeHaveSendConfirmationLinkToEmail, locale),
				variant: 'success',
			});

			onClose();
		} catch (e) {
			console.log(e);
			throwSnackbar({
				exception: e,
				msg: getTranslation(trSomethingWentWrongPleaseTryAgainLater, locale),
				variant: 'error',
			});
		}
	};

	const setOpen = (open) => {
		if (!open) onClose();
	};

	return (
		<MuiConfirmDialog
			body={
				<Fragment>
					<DialogContentText>
						<Tr tr={trIfYouSubscribeToOurNewsletter} />
					</DialogContentText>
					<DialogContentText>
						<strong>{EMAIL}</strong>
					</DialogContentText>
					<DialogContentText>
						<Tr tr={trYouWillReceiveAConfirmationLink} />
					</DialogContentText>
				</Fragment>
			}
			cancel_tr={trCancel}
			confirmText={<Tr tr={trSubscribeNow} />}
			onConfirm={handleOnConfirm}
			open={true}
			title={<Tr tr={trSubscribeToOurNewsletter} />}
			setOpen={setOpen}
		/>
	);
};
MailingListSubscriptionDialog.propTypes = {
	EMAIL: PropTypes.string,
	onClose: PropTypes.func,
};
