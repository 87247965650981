import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import Zoom from '@material-ui/core/Zoom';
import { PropTypes } from 'prop-types';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';

export const FormError = ({ errorTr, height }) => {
	if (_.isNil(errorTr)) return null;

	return (
		<Zoom in={true}>
			<Alert severity={'error'}>
				<Tr tr={errorTr} />
			</Alert>
		</Zoom>
	);

	return (
		<Box
			style={{
				height: height,
			}}
		>
			{errorTr != null && (
				<Zoom in={true}>
					<Typography align={'center'} component={'div'} color={'primary'}>
						<Tr tr={errorTr} />
					</Typography>
				</Zoom>
			)}
		</Box>
	);
};

FormError.propTypes = {
	errorTr: PropTypes.object,
	height: PropTypes.number,
};

FormError.defaultProps = {
	errorTr: null,
	height: 48,
};
