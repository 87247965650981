import React from 'react';
// eslint-disable-next-line no-unused-vars
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 444,
		},
	},
}));

export const ShopFormBreakpointContainer = ({ children, theme }) => {
	const classes = useStyles(theme);
	return <div className={clsx(classes.root)}>{children}</div>;
};

ShopFormBreakpointContainer.propTypes = {};
