import _ from 'lodash';

export const PasswordResetForm = ({ formData, setFormData }) => {
	return {
		onChange: (formData) => {
			setFormData(_.assign({}, formData, { PASSWORD: null }));
		},
		fieldGlobals: {
			inputProps: {
				fullWidth: true,
				size: 'small',
				styleVariant: 'outlined',
				shrink: true,
				hideErrorsIfEmpty: true,
				emptyValue: '',
			},
		},
		fields: {
			USERNAME: {
				variant: 'email',
			},
		},
		values: {
			USERNAME: formData.USERNAME,
		},
	};
};
