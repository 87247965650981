import { ShopPageBasicHeader } from './ShopPageBasicHeader';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { MuiCSSContainer } from '../components/MuiCSSContainer';
import PropTypes from 'prop-types';
import { TextFooter } from '../components/Footer/TextFooter';

const useStyles = makeStyles((theme) => ({
	Container: {
		position: 'relative',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}));

export const ShopPageSingleForm = ({ children, ...pageProps }) => {
	const classes = useStyles();
	return (
		<ShopPageBasicHeader {...pageProps} loginPage>
			<MuiCSSContainer
				className={clsx(classes.Container)}
				disableGutters={false}
			>
				{/*<PageCard noPadding={noPadding}>{children}</PageCard>*/}
				{children}
				<TextFooter />
			</MuiCSSContainer>
		</ShopPageBasicHeader>
	);
};

ShopPageSingleForm.propTypes = {
	noPadding: PropTypes.bool,
};
