import React, { useState } from 'react';
import _ from 'lodash';
import { MailingListSubscriptionDialog } from '../../components/MailingListSubscription/MailingListSubscriptionDialog';

const Context = React.createContext(null);
Context.displayName = 'MailingListSubscription.Context';

const Container = ({ optin, children }) => {
	const [data, setData] = useState(null);

	const register = async (EMAIL) => {
		setData({ EMAIL, optin });
	};

	const onClose = () => setData(null);

	const value = {
		register,
	};

	return (
		<Context.Provider value={value}>
			{children}
			{!_.isNil(data) && (
				<MailingListSubscriptionDialog onClose={onClose} {...data} />
			)}
		</Context.Provider>
	);
};

export const MailingListSubscription = {
	Container: Container,
	Context: Context,
};
