export const PickupAddress = {
	FIRSTNAME: '',
	LASTNAME: '',
	COMPANY: 'LToys - Bricks, Figures & More',
	DEPARTMENT: '',
	ADDITION: '',
	STREET: '141, route de Treves',
	CITY: 'Niederanven',
	ZIPCODE: '6940',
	COUNTRY_CODE: 'LU',
	COUNTRY: {
		DE: 'Luxemburg',
		EN: 'Luxembourg',
	},
	POBOX_NUMBER: '',
	ID: 'AID-1000',
	ADDRESS_TYPES: ['DELIVERY'],
	PHONE_NUMBER: '+352 28 67 63 67',
};
