import React, { Fragment, useContext, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// noinspection ES6CheckImport
import { MuiAppContext, MuiLink } from '@lsoft/mui';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import { Tr } from '@lsoft/translate';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TransparentCard } from '../TransparentCard';
import {
	trContinueShopping,
	trCreateNewAccount,
	trLogin,
	trPasswordReset,
	trYouWantToRegister,
} from './LoginView.tr';
import { PasswordResetView } from '../Register/PasswordResetView';
import { VerifyPinView } from '../VerifyPin/VerifyPinView';
import { SignInView } from './SignInView';
import { trConfirmYourAccount } from '../VerifyPin/VerifyPinView.tr';

const ViewFooter = () => {
	const { loginInfo } = useContext(MuiAppContext);
	const setRenderLogin = _.get(loginInfo, 'setRenderLogin', () => {});
	return (
		<Fragment>
			<Divider />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant={'overline'} align={'center'} component={'div'}>
						<Tr tr={trYouWantToRegister} />
					</Typography>
					<MuiLink
						href={'/register/sign_up'}
						linkprops={{
							onClick: () => setRenderLogin(false),
						}}
					>
						<Button fullWidth variant={'outlined'} disableElevation>
							<Tr tr={trCreateNewAccount} />
						</Button>
					</MuiLink>
				</Grid>
				<Grid item xs={12}>
					<Grid container justifyContent={'center'}>
						<Button
							fullWidth
							variant={'outlined'}
							onClick={() => {
								setRenderLogin(false);
							}}
						>
							<Tr tr={trContinueShopping} />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Fragment>
	);
};

export const LoginViewContext = React.createContext(null);
LoginViewContext.displayName = 'LoginViewContext';

const forms = {
	login: {
		form: SignInView,
		tr: trLogin,
	},
	password_reset: {
		form: PasswordResetView,
		tr: trPasswordReset,
	},
	verify_pin: {
		form: VerifyPinView,
		tr: trConfirmYourAccount,
	},
};

export const LoginView = ({ error, renderCard, href }) => {
	const [formData, setFormData] = useState({
		USERNAME: null,
		PASSWORD: null,
		PIN: null,
	});

	const [cardMode, setCardMode] = useState('login');

	const value = {
		error,
		formData,
		setFormData,
		setCardMode,
	};

	const Form = forms[cardMode].form;
	const formProps = {
		username: formData.USERNAME,
	};
	if (!_.isNil(href)) {
		formProps['href'] = href;
	}

	const renderContent = () => {
		if (renderCard) {
			return (
				<Fragment>
					<Card>
						<CardHeader title={<Tr tr={forms[cardMode].tr} />} />
						<CardContent>
							<Form {...formProps} />
						</CardContent>
					</Card>
					{cardMode !== 'verify_pin' && (
						<TransparentCard>
							<CardContent>
								<ViewFooter />
							</CardContent>
						</TransparentCard>
					)}
				</Fragment>
			);
		} else {
			return <Form {...formProps} />;
		}
	};
	return (
		<LoginViewContext.Provider value={value}>
			{renderContent()}
		</LoginViewContext.Provider>
	);
};

LoginView.propTypes = {
	error: PropTypes.any,
	renderCard: PropTypes.bool,
	href: PropTypes.string,
};
LoginView.defaultProps = {
	error: null,
	renderCard: true,
	href: null,
};
