import React, { useCallback, useContext } from 'react';
// noinspection ES6CheckImport
import { MuiForm, MuiFormContext, MuiFormField, useWebapi } from '@lsoft/mui';
import { PasswordResetForm } from './PasswordReset.form';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getTranslation, Tr } from '@lsoft/translate';
import { trBackToSignIn, trPasswordResetNote } from '../Login/LoginView.tr';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { LoginViewContext } from '../Login/LoginView';
import { makeStyles } from '@material-ui/core';
import { useRouter } from 'next/router';
import {
	trAccountNotRegistered,
	trContinue,
	trWeSendYouAnPasswordResetLinkViaEmail,
} from './Register.tr';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import _ from 'lodash';
import { trSomethingWentWrongPleaseTryAgainLater } from '../CustomerCard/CustomerCardView.tr';
import { shop_customer_contact_password_request } from '@lsoft/shared/backend/shop/shop';
import { convert_axios_error } from '../../utils/errors';

const useStyles = makeStyles(() => ({
	ErrorArea: {
		height: 40,
		minHeight: 40,
	},
}));

export const PasswordResetView = () => {
	const classes = useStyles();
	const throwSnackbar = useShopSnackbar();
	const { locale } = useRouter();
	const webapi = useWebapi();
	// noinspection JSCheckFunctionSignatures
	const { formData, setFormData, setCardMode } = useContext(LoginViewContext);

	const handlePasswordReset = useCallback(
		async (email, setFormvalue) => {
			try {
				await shop_customer_contact_password_request({ email });
				throwSnackbar({
					msg: getTranslation(trWeSendYouAnPasswordResetLinkViaEmail, locale),
					variant: 'success',
				});
			} catch (e) {
				const error_class = _.get(
					convert_axios_error(e),
					['ERROR_CLASS'],
					'UnknownError'
				);
				if (error_class === 'CustomerWithEmailNotFound') {
					throwSnackbar({
						exception: e,
						msg: getTranslation(trAccountNotRegistered, locale),
						variant: 'error',
						send_to_sentry: false,
					});
				} else {
					throwSnackbar({
						exception: e,
						msg: getTranslation(
							trSomethingWentWrongPleaseTryAgainLater,
							locale
						),
						variant: 'error',
					});
				}
			}
			setFormvalue('USERNAME', '');
		},
		[webapi]
	);
	return (
		<MuiForm {...PasswordResetForm({ formData, setFormData })}>
			<MuiFormContext.Consumer>
				{(value) => {
					return (
						<Grid container direction={'column'} spacing={2}>
							<Grid item>
								<MuiFormField name={'USERNAME'} />
							</Grid>
							<Grid item>
								<div style={{ height: 43 }}>
									<Typography variant={'caption'}>
										<Tr tr={trPasswordResetNote} />
									</Typography>
								</div>
							</Grid>
							<Grid item>
								<Button
									fullWidth
									variant={'contained'}
									color={'primary'}
									disabled={!value.valid}
									onClick={() => {
										// noinspection JSIgnoredPromiseFromCall
										handlePasswordReset(
											value.formData['USERNAME'],
											value.setValue
										);
									}}
								>
									<Tr tr={trContinue} />
								</Button>
							</Grid>
							<Grid item className={clsx(classes.ErrorArea)}></Grid>
							<Grid item>
								<Button onClick={() => setCardMode('login')}>
									<Tr tr={trBackToSignIn} />
								</Button>
							</Grid>
						</Grid>
					);
				}}
			</MuiFormContext.Consumer>
		</MuiForm>
	);
};
