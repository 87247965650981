// noinspection ES6CheckImport
import { MuiWebapi } from '@lsoft/mui';
import _ from 'lodash';

export const marketing_mailing_list_register_post = async ({
	email,
	newsletter_name,
	newsletter_group,
	language,
	optin,
}) => {
	// // noinspection JSUnresolvedVariable
	// const res = await MuiWebapi.get().lsfastapi.marketing.paths[
	// 	'/marketing/mailing_list/register'
	// ].post({ email, mailing_list, language_code });
	// return _.get(res, `data`, null);
	const res = await MuiWebapi.get().lsfastapi.global.paths[
		'/email/account'
	].put({ email, newsletter_name, newsletter_group, language, optin });
	return _.get(res, `data`, null);
};
