export const trShowMore = {
	DE: 'Mehr anzeigen',
	EN: 'Show more',
	FR: 'Afficher plus',
};
export const trShowLess = {
	DE: 'Weniger anzeigen',
	EN: 'Show less',
	FR: 'Afficher moins',
};
