import { ShopPageBasicHeader } from './ShopPageBasicHeader';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

export const useStyles = makeStyles((theme) => ({
	PageContentCard: {
		background: '#fafafa',
	},
	SideBar: {
		width: theme.sizes.sideBarWidth,
	},
}));

export const ShopPageCustom = ({ children, ...pageProps }) => {
	return <ShopPageBasicHeader {...pageProps}>{children}</ShopPageBasicHeader>;
};
ShopPageCustom.propTypes = {
	noPadding: PropTypes.bool,
};
