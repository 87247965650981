import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MuiAppContext } from '@lsoft/mui';
import _ from 'lodash';
import { ShopPageBasic } from './ShopPageBasic';
import { ShopPageSidebarMenu } from './ShopPageSidebarMenu';
import { ShopPageLogin } from './ShopPageLogin';
import { ShopPageCore } from './ShopPageCore';
import { ShopPageCustom } from './ShopPageCustom';
import { ShopPageBasicHeader } from './ShopPageBasicHeader';
import { ShopPageSingleForm } from './ShopPageSingleForm';
import { Restricted } from '@lsoft/shared/components/am/Restricted';

const pageComponents = {
	core: ShopPageCore,
	basic: ShopPageBasic,
	basicHeader: ShopPageBasicHeader,
	singleForm: ShopPageSingleForm,
	sidebarMenu: ShopPageSidebarMenu,
	custom: ShopPageCustom,
};

const ShopPage = ({ children, variant, ...pageProps }) => {
	const { loginInfo } = useContext(MuiAppContext);
	const { renderLogin } = loginInfo;

	if (renderLogin) {
		return (
			<React.Fragment>
				<Restricted />
				<ShopPageLogin />
			</React.Fragment>
		);
	}

	const PageComponent = _.get(pageComponents, variant, null);

	if (_.isNil(PageComponent))
		throw new Error(`ShopPage variant '${variant}' not existing.`);
	return <PageComponent {...pageProps}>{children}</PageComponent>;
};

ShopPage.propTypes = {
	variant: PropTypes.string,
};
ShopPage.defaultProps = {
	variant: 'basic',
};

export { ShopPage };
