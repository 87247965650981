import React from 'react';
import {
	Box,
	CardContent,
	IconButton,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { TransparentCard } from '../TransparentCard';
import { grey } from '@material-ui/core/colors';
import { MuiCSSContainer } from '../MuiCSSContainer';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import {
	trFollowUs,
	trReceiveInformationProductSales,
	trSubscribeToOurNewsletter,
} from './Footer.tr';
import { MailingListSubscriptionByEmailAddress } from '../MailingListSubscription/MailingListSubscriptionByEmailAddress';
import { MuiLink } from '@lsoft/mui';
import { NextAssetImage } from '@lsoft/shared/components/ui/NextAssetImage';

const useStyles = makeStyles((theme) => ({
	root: {
		background: grey[400],
	},
	MailingListInputWrap: {
		boxShadow: 'none',
		background: grey[100],

		[theme.breakpoints.up('sm')]: {
			// width: 490,
		},
	},
}));

export const NewsletterSignUp = () => {
	const classes = useStyles();
	return (
		<TransparentCard>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<Typography variant={'subtitle2'}>
							<Tr tr={trSubscribeToOurNewsletter} />
						</Typography>
						<Typography variant={'caption'} component={'p'}>
							<Tr tr={trReceiveInformationProductSales} />
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.MailingListInputWrap}>
							<MailingListSubscriptionByEmailAddress />
						</Paper>
					</Grid>
				</Grid>
			</CardContent>
		</TransparentCard>
	);
};

const SocialMedia = () => {
	return (
		<TransparentCard>
			<CardContent>
				<Typography variant={'subtitle2'}>
					<Tr tr={trFollowUs} />
					<Grid container>
						<Grid item>
							<MuiLink
								href={'https://www.facebook.com/ltoys.lu'}
								fullWidth
								linkprops={{
									target: '_blank',
								}}
							>
								<IconButton>
									<FacebookIcon />
								</IconButton>
							</MuiLink>
						</Grid>
						<Grid item>
							<MuiLink
								href={'https://www.instagram.com/ltoys.lu'}
								fullWidth
								linkprops={{
									target: '_blank',
								}}
							>
								<IconButton>
									<InstagramIcon />
								</IconButton>
							</MuiLink>
						</Grid>
					</Grid>
				</Typography>
			</CardContent>
		</TransparentCard>
	);
};

export const LegoDigitalCatalog = () => {
	const tr_title = {
		DE: 'Blättern Sie durch den aktuellen LEGO® Katalog',
		EN: 'Browse through the latest LEGO® catalog',
		FR: 'Parcourez le dernier catalogue LEGO®',
	};
	const tr_description = {
		DE: 'Entdecken Sie die Welt von LEGO® mit dem interaktiven Katalog! Der Katalog liegt in deutschen Sprache vor.',
		EN: 'Discover the world of LEGO® with this interactive catalog. The catalog is available in German.',
		FR: 'Découvrez le monde de LEGO® avec ce catalogue interactif. Le catalogue est disponible en allemand.',
	};

	return (
		<TransparentCard>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<Typography variant={'subtitle2'}>
							<Tr tr={tr_title} />
						</Typography>
						<Typography variant={'caption'} component={'p'}>
							<Tr tr={tr_description} />
						</Typography>
					</Grid>
					<Grid item xs={8}>
						<div className={'flex gap-4'}>
							<a
								href={
									'https://catalogs.lego.com/lego-sets-for-kids/befr/?page=1'
								}
								target={'_blank'}
								rel={'noreferrer'}
							>
								<NextAssetImage
									asset={{
										asset_type: 'SLIDER_SLIM',
										url: {
											ALL: '/images/lego_catalog/kids.png',
										},
										url_type: 'RELATIVE',
										caption: tr_title,
										active: true,
									}}
									width={250}
									height={250}
								/>
							</a>
							<a
								href={
									'https://catalogs.lego.com/lego-sets-for-adults/?icmp=LP-SHCC-Standard-BC_CC_Standard_Adults-TH-NO-OIU88W5XCB'
								}
								target={'_blank'}
								rel={'noreferrer'}
							>
								<NextAssetImage
									asset={{
										asset_type: 'SLIDER_SLIM',
										url: {
											ALL: '/images/lego_catalog/adults.png',
										},
										url_type: 'RELATIVE',
										caption: tr_title,
										active: true,
									}}
									width={250}
									height={250}
								/>
							</a>
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</TransparentCard>
	);
};

const FooterRowSocial = () => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.root)}>
			<MuiCSSContainer>
				<Grid container justifyContent={'space-between'}>
					<Grid item xs={12} md={5}>
						<NewsletterSignUp />
					</Grid>
					<Grid item xs={6} md={4}>
						<LegoDigitalCatalog />
					</Grid>
					<Grid item xs={12} md={'auto'}>
						<SocialMedia />
					</Grid>
				</Grid>
			</MuiCSSContainer>
		</Box>
	);
};

export { FooterRowSocial };
