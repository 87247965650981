import React from 'react';
import { TagMenu, TagMenuPromotions } from '../TagMenu/TagMenu';
import {
	MenuBooks,
	MenuCobiSeries,
	MenuLegoAge,
	MenuLegoSeries,
	MenuLighting,
	MenuOtherBrands,
	MenuStorageBoxes,
} from '../../constants/MenuEntries';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PromotionsContainer } from '@lsoft/shared/components/Promotion/container/Promotions';
import { useRouter } from 'next/router';
import { CollapseArea } from '../CollapseArea/CollapseArea';

const useStyles = makeStyles((theme) => ({
	TagNavHorizontalContainer: {
		width: theme.sizes.sideBarWidth,
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
	},
}));

const convertNewCobiCategoriesToOldMenu = (new_cobi_categorie) => {
	return {
		title: new_cobi_categorie.text,
		description: {},
		items: {},
	};
};

const TagNavSidebar = ({ theme }) => {
	const { query } = useRouter();
	const classes = useStyles({ theme });
	const query_params = _.get(query, 'grouptags', '');
	const cobi_selected = query_params.includes('COBI');

	const LegoMenu = (
		<CollapseArea collapsedSize={400} key={'LEGO'}>
			<TagMenu data={MenuLegoSeries} />
		</CollapseArea>
	);

	const CobiMenu = (
		<CollapseArea collapsedSize={400} key={'COBI'}>
			<TagMenu data={MenuCobiSeries} />
		</CollapseArea>
	);

	const themes_order = cobi_selected
		? [CobiMenu, LegoMenu]
		: [LegoMenu, CobiMenu];

	return (
		<nav className={clsx(classes.TagNavHorizontalContainer)}>
			<PromotionsContainer active={true} without_empty>
				<TagMenuPromotions />
			</PromotionsContainer>
			<TagMenu data={MenuLegoAge} />
			<TagMenu data={MenuOtherBrands} />
			{themes_order}
			{/*{themes_order.map((entry, index) => (*/}
			{/*	<CollapseArea key={index} collapsedSize={780}>*/}
			{/*		<TagMenu data={entry} />*/}
			{/*	</CollapseArea>*/}
			{/*))}*/}
			<TagMenu data={MenuLighting} />
			<TagMenu data={MenuStorageBoxes} />
			<TagMenu data={MenuBooks} />
		</nav>
	);
};

TagNavSidebar.propTypes = {};
TagNavSidebar.defaultProps = {};

export { TagNavSidebar };
