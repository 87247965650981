import { ShopPageBasicHeader } from './ShopPageBasicHeader';
import { Container, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { LoginView } from '../components/Login/LoginView';
import { TextFooter } from '../components/Footer/TextFooter';
import React from 'react';
import clsx from 'clsx';
import { ShopFormBreakpointContainer } from '../components/ShopFormBreakpointContainer';

const useStyles = makeStyles((theme) => ({
	Container: {
		paddingTop: theme.spacing(2),
	},
}));

export const ShopPageLogin = ({ theme }) => {
	const classes = useStyles({ theme });
	return (
		<ShopPageBasicHeader loginPage>
			<Container maxWidth={'lg'} className={clsx(classes.Container)}>
				<Grid
					container
					direction={'column'}
					justifyContent={'center'}
					alignItems={'center'}
					spacing={2}
				>
					<Grid item>
						<ShopFormBreakpointContainer>
							<LoginView />
						</ShopFormBreakpointContainer>
					</Grid>
					<Grid item xs={12}>
						<TextFooter />
					</Grid>
				</Grid>
			</Container>
		</ShopPageBasicHeader>
	);
};
