import React from 'react';
import {
	Box,
	Button,
	CardContent,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import StoreIcon from '@material-ui/icons/Store';
import { TransparentCard } from '../TransparentCard';
import CardHeader from '@material-ui/core/CardHeader';
import { grey } from '@material-ui/core/colors';
import { MuiCSSContainer } from '../MuiCSSContainer';
import {
	trClickAndCollect,
	trCustomerCard,
	trCustomerCardCollectPoints,
	trFreeShipping,
	trOrderValueShippingFree,
	trOurServices,
	trOurTeamOfExpertsWillHelpYou,
	trPlaceOrderOnlineAndCollect,
	trSendUsAMessage,
	trYouHaveQuestions,
	trYouNeedHelp,
} from './Footer.tr';

const useStyles = makeStyles((theme) => ({
	root: {
		background: grey[300],
	},
	CardHeader: {
		paddingBottom: 0,
	},
	ServiceItem: {
		'& .MuiSvgIcon-root': {
			display: 'block',
			margin: [[0, 'auto', theme.spacing(1), 'auto']],
		},
	},
}));

const Support = () => {
	const classes = useStyles();
	return (
		<TransparentCard>
			<CardHeader
				title={<Tr tr={trYouNeedHelp} />}
				className={clsx(classes.CardHeader)}
			/>
			<CardContent>
				<Typography gutterBottom variant={'body2'}>
					<Tr tr={trYouHaveQuestions} />
				</Typography>
				<Typography variant={'body2'}>
					<Tr tr={trOurTeamOfExpertsWillHelpYou} />
				</Typography>
				<Button
					startIcon={<MailOutlineIcon />}
					onClick={() => (window.location = 'mailto:support@ltoys.lu')}
				>
					<Tr tr={trSendUsAMessage} />
				</Button>
			</CardContent>
		</TransparentCard>
	);
};

const Service = () => {
	const classes = useStyles();
	return (
		<TransparentCard>
			<CardHeader
				title={<Tr tr={trOurServices} />}
				className={clsx(classes.CardHeader)}
			/>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item md={4} className={clsx(classes.ServiceItem)}>
						<LoyaltyIcon />
						<Typography variant={'subtitle2'} align={'center'}>
							<Tr tr={trCustomerCard} />
						</Typography>
						<Typography variant={'caption'} align={'center'} component={'p'}>
							<Tr tr={trCustomerCardCollectPoints} />
						</Typography>
					</Grid>
					<Grid item md={4} className={clsx(classes.ServiceItem)}>
						<LocalShippingIcon />
						<Typography variant={'subtitle2'} align={'center'}>
							<Tr tr={trFreeShipping} />
						</Typography>
						<Typography variant={'caption'} align={'center'} component={'p'}>
							<Tr tr={trOrderValueShippingFree} />
						</Typography>
					</Grid>
					<Grid item md={4} className={clsx(classes.ServiceItem)}>
						<StoreIcon />
						<Typography variant={'subtitle2'} align={'center'}>
							<Tr tr={trClickAndCollect} />
						</Typography>
						<Typography variant={'caption'} align={'center'} component={'p'}>
							<Tr tr={trPlaceOrderOnlineAndCollect} />
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</TransparentCard>
	);
};

const FooterRowSupport = () => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.root)}>
			<MuiCSSContainer>
				<Grid container>
					<Grid item xs={12} sm={6}>
						<Support />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Service />
					</Grid>
				</Grid>
			</MuiCSSContainer>
		</Box>
	);
};

export { FooterRowSupport };
