export const SignInViewForm = ({ formData, setFormData }) => {
	return {
		onChange: (formData) => {
			setFormData(formData);
		},
		fieldGlobals: {
			inputProps: {
				fullWidth: true,
				size: 'small',
				styleVariant: 'outlined',
				shrink: true,
				hideErrorsIfEmpty: true,
				emptyValue: '',
			},
		},
		fields: {
			USERNAME: {
				variant: 'email',
			},
			PASSWORD: {
				variant: 'password_unvalidated',
			},
		},
		values: {
			USERNAME: formData.USERNAME,
			PASSWORD: formData.PASSWORD,
		},
	};
};
