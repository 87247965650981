import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// noinspection ES6CheckImport
import { Countries as MuiCountries } from '@lsoft/mui';
import { getTranslation } from '@lsoft/translate';
import _ from 'lodash';
import { data as shipping_method_data } from '../../constants/shipping_method.data';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { trSomethingWentWrongPleaseTryAgainLater } from '../../components/CustomerCard/CustomerCardView.tr';
import { erp_countries_get } from '@lsoft/shared/backend/erp/countries';

const load = async () => {
	return await erp_countries_get();
};

const Container = ({ children }) => {
	const [data, setData] = useState(null);
	const { locale } = useRouter();
	const throwSnackbar = useShopSnackbar();

	useEffect(() => {
		let isSubscribed = true;
		const as = async () => {
			try {
				const res = await load();
				if (isSubscribed) {
					setData(res);
				}
			} catch (e) {
				throwSnackbar({
					exception: e,
					msg: getTranslation(trSomethingWentWrongPleaseTryAgainLater, locale),
					variant: 'error',
				});
			}
		};
		// noinspection JSIgnoredPromiseFromCall
		as();
		return () => (isSubscribed = false);
	}, []);
	if (_.isNil(data)) return null;

	const shipping_method_cc = _.uniq(
		_.flatten(
			Object.values(shipping_method_data).map((shipping_method) => {
				return Object.keys(shipping_method).map((i) => {
					return i;
				});
			})
		)
	);

	let shipping_country_data = {};

	shipping_method_cc.map((cc) => {
		shipping_country_data[cc] = data[cc];
	});

	return (
		<MuiCountries.Container data={shipping_country_data}>
			{children}
		</MuiCountries.Container>
	);
};
Container.propTypes = {};
Container.defaultProps = {};
Container.displayName = 'Countries.Container';

export const Countries = _.assign({}, MuiCountries, {
	Container,
});
