import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import { PickupAddress } from '../../constants/PickupAddress';
import { MuiLink } from '@lsoft/mui';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { Countries } from '../../container/shop/Countries';

export const StoreAddress = ({ hidePhoneNumber }) => {
	return (
		<Countries.Container>
			<Typography variant={'subtitle2'} gutterBottom>
				{PickupAddress.COMPANY}
			</Typography>
			<Typography variant={'body2'}>{PickupAddress.STREET}</Typography>
			<Typography variant={'body2'}>
				{PickupAddress.ZIPCODE}&nbsp;{PickupAddress.CITY}
			</Typography>
			<Typography variant={'body2'} gutterBottom={!hidePhoneNumber}>
				<StoreAddressCountryName />
			</Typography>
			{!hidePhoneNumber && (
				<Typography variant={'body2'} gutterBottom>
					<MuiLink href={'tel:' + PickupAddress.PHONE_NUMBER}>
						{PickupAddress.PHONE_NUMBER}
					</MuiLink>
				</Typography>
			)}
		</Countries.Container>
	);
};

StoreAddress.propTypes = {
	hidePhoneNumber: PropTypes.bool,
};
StoreAddress.defaultProps = {
	hidePhoneNumber: false,
};

const StoreAddressCountryName = () => {
	const { locale } = useRouter();
	const { countryName } = useContext(Countries.Context);
	return countryName(_.get(PickupAddress, 'COUNTRY_CODE', null), locale);
};
