import React from 'react';
import { MuiLink } from '@lsoft/mui';
import { makeStyles, MenuItem, MenuList } from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import clsx from 'clsx';
import ListSubheader from '@material-ui/core/ListSubheader';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.text.primary,
		'&:hover,:active,:focused': {
			color: theme.palette.primary,
		},
	},
}));

const FooterMenuList = ({ list }) => {
	const classes = useStyles();
	return (
		<MenuList
			dense
			subheader={
				<ListSubheader disableSticky>
					<Tr tr={list.title} />
				</ListSubheader>
			}
		>
			{Object.values(list.items).map((value, index) => {
				return (
					<MenuItem key={index}>
						<MuiLink
							href={value.link}
							fullWidth
							linkprops={{
								className: clsx(classes.link),
								target: value.target_blank ? '_blank' : '_self',
							}}
						>
							<Tr tr={value.title} />
						</MuiLink>
					</MenuItem>
				);
			})}
		</MenuList>
	);
};

FooterMenuList.propTypes = {
	list: PropTypes.object,
};

export { FooterMenuList };
