export const trWeSendYouAnPasswordResetLinkViaEmail = {
	DE: 'Wir haben Ihnen einen Link geschickt, um das Passwort neu zu vergeben',
	EN: 'We have sent you a link to reassign the password',
	FR: 'Nous vous avons envoyé un lien pour réinitialiser le mot de passe',
};
export const trContinue = {
	DE: 'Weiter',
	EN: 'Proceed',
	FR: 'Continuer',
};
export const trErrorWhileVerifyLink = {
	DE: 'Bei der Verifizierung Ihres Accounts scheint es zu einem Problem gekommen zu sein. Möglicherweise ist der Aktivierungslink abgelaufen und nicht mehr gültig.',
	EN: 'There seems to be a problem with the verification of your account. Possibly the activation link has expired and is no longer valid.',
	// prettier-ignore
	FR:
		'Il semble y avoir un problème avec la vérification de votre compte. Il est possible que le lien d\'activation ait expiré et ne soit plus valable.',
};
export const trSomethingWentWrong = {
	DE: 'Etwas ist schiefgelaufen ...',
	EN: 'Something went wrong ...',
	FR: 'Quelque chose ne va pas ...',
};
export const trBackToLogin = {
	DE: 'Zurück zur Anmeldung',
	EN: 'Back to login form',
	FR: 'Retour à la connexion',
};
export const trYouAlreadyHaveAnAccountQuestion = {
	DE: 'Sie haben bereits einen Account?',
	EN: 'You already have an account?',
	FR: 'Vous avez déjà un compte ?',
};
export const trNowSignUp = {
	DE: 'Jetzt registrieren',
	EN: 'Sign up now',
	FR: 'Connexion',
};
export const trAcceptGTCWithLogin = {
	DE: 'Mit Ihrer Anmeldung erklären Sie sich mit den <a href="/gtc" target="_blank">Allgemeine Geschäftsbedingungen</a> einverstanden. Sie haben die <a href="/privacy_policy" target="_blank">Datenschutzerklärung</a>, das <a href="/gtc#withdrawal" target="_blank">Widerrufsrecht</a>, sowie die die <a href="/cookie_policy" target="_blank">Hinweise zu Cookies</a> wahrgenommen.',
	EN: 'With your registration you agree to the general <a href="/gtc" target="_blank">General Terms and Conditions</a>. You also agree to the <a href="/privacy_policy" target="_blank">Privacy Policy</a>, the <a href="/gtc#withdrawal" target="_blank">Right of Revocation</a> and the <a href="/cookie_policy" target="_blank">Cookie Policy</a>.',
	FR: 'En vous inscrivant, vous acceptez les <a href="/gtc" target="_blank">conditions générales</a>. Vous acceptez également la <a href="/privacy_policy" target="_blank">politique de confidentialité</a>, le <a href="/gtc#withdrawal" target="_blank">droit de révocation</a> et la <a href="/cookie_policy" target="_blank">politique en matière de cookies</a>.',
};
export const trCreateAccount = {
	DE: 'Konto erstellen',
	EN: 'Create an account',
	FR: 'Créer un compte',
};
export const trAccountAlreadyRegistered = {
	DE: 'Dieser Account ist bereits registriert',
	EN: 'This account is already registered',
	FR: 'Ce compte est déjà enregistré',
};
export const trAccountNotRegistered = {
	DE: 'Die angegebene E-Mail ist nicht registriert.',
	EN: 'The provided email is not registered.',
	// prettier-ignore
	FR: 'L\'email fourni n\'est pas enregistré.',
};
export const trSetNewPassword = {
	DE: 'Vergeben Sie eine neues Passwort',
	EN: 'Assign a new password',
	FR: 'Attribuer un nouveau mot de passe',
};
export const trSave = {
	DE: 'Speichern',
	EN: 'Save',
	FR: 'Sauvegarder',
};
export const trPasswordSuccessfullyChanged = {
	DE: 'Passwort erfolgreich geändert',
	EN: 'Password successfully changed',
	FR: 'Changement de mot de passe réussi',
};
export const trLinkIsNotValid = {
	DE: 'Ihr Link um ein neues Passwort zu vergeben ist nicht valide',
	EN: 'Your link to set a new password is not valid',
	// prettier-ignore
	FR: 'Votre lien pour définir un nouveau mot de passe n\'est plus valable',
};
export const trLinkHasExpired = {
	DE: 'Ihr Link um ein neues Passwort zu vergeben ist nicht mehr gültig',
	EN: 'Your link to set a new password is no longer valid',
	// prettier-ignore
	FR: 'Votre lien pour définir un nouveau mot de passe n\'est plus valable',
};
