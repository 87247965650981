import { Divider, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * MuiDivider - Returns a material ui divider with custom gutter
 * @param gutterTop
 * @param gutterRight
 * @param gutterBottom
 * @param gutterLeft
 * @returns {JSX.Element}
 * @constructor
 */
export const MuiDivider = ({
	gutterTop,
	gutterRight,
	gutterBottom,
	gutterLeft,
	noGutter,
}) => {
	const theme = useTheme();

	return (
		<Divider
			style={{
				marginTop: !noGutter ? theme.spacing(gutterTop) : 0,
				marginRight: !noGutter ? theme.spacing(gutterRight) : 0,
				marginBottom: !noGutter ? theme.spacing(gutterBottom) : 0,
				marginLeft: !noGutter ? theme.spacing(gutterLeft) : 0,
			}}
		/>
	);
};
MuiDivider.propTypes = {
	gutterTop: PropTypes.number,
	gutterRight: PropTypes.number,
	gutterBottom: PropTypes.number,
	gutterLeft: PropTypes.number,
	noGutter: PropTypes.bool,
};
MuiDivider.defaultProps = {
	gutterTop: 2,
	gutterRight: 0,
	gutterBottom: 2,
	gutterLeft: 0,
	noGutter: false,
};
