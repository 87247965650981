import React from 'react';
import { Box } from '@material-ui/core';
import { FooterRowSupport } from './FooterRowSupport';
import { FooterRowSocial } from './FooterRowSocial';
import { FooterRowMenu } from './FooterRowMenu';
import { FooterRowDefault } from './FooterRowDefault';

const Footer = () => {
	return (
		<Box>
			<FooterRowSupport />
			<FooterRowSocial />
			<FooterRowMenu />
			<FooterRowDefault />
		</Box>
	);
};
export { Footer };
