export const trSubscribeNewsletter = {
	DE: 'Newsletter abonnieren',
	EN: 'Subscribe newsletter',
	// prettier-ignore
	FR: 'S\'abonner à la newsletter',
};
export const trSubscribeNow = {
	DE: 'Jetzt abonnieren',
	EN: 'Subscribe now',
	// prettier-ignore
	FR: 'S\'abonner maintenant',
};
export const trYouWillReceiveAConfirmationLink = {
	DE: 'Sie erhalten einen separate Nachricht mit einem Bestätigungslink.',
	EN: 'You will receive a separate message with a confirmation link.',
	FR: 'Vous recevrez un message séparé avec un lien de confirmation.',
};
export const trIfYouSubscribeToOurNewsletter = {
	DE:
		'Wenn Sie unseren Newsletter abonnieren, senden wir Ihnen in Zukunft bequem alle Neuigkeiten direkt in Ihr Postfach:',
	EN:
		'If you subscribe to our newsletter, we will conveniently send you all news directly to your inbox in the future:',
	// prettier-ignore
	FR:
		'Si vous vous inscrivez à notre bulletin d\'information, nous vous enverrons à l\'avenir toutes les nouvelles directement dans votre boîte de réception :',
};
export const trWeHaveSendConfirmationLinkToEmail = {
	DE:
		'Wir haben Sie soeben einen Bestätigungslink an Ihre E-Mail-Adresse geschickt.',
	EN: 'We have just sent you a confirmation link to your email address.',
	FR:
		'Nous venons de vous envoyer un lien de confirmation à votre adresse électronique.',
};
export const trSubscribeToOurNewsletter = {
	DE: 'Abonnieren Sie unseren Newsletter',
	EN: 'Subscribe to our newsletter',
	// prettier-ignore
	FR: 'S\'abonner à notre newsletter',
};
