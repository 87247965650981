import { Box, Typography } from '@material-ui/core';
import { MuiCSSContainer } from '../MuiCSSContainer';
import { TextFooter } from './TextFooter';
import React, { useContext } from 'react';
import { MuiAppContext } from '@lsoft/mui';

const Version = () => {
	const {
		appInfo: { version },
	} = useContext(MuiAppContext);
	return (
		<Typography variant={'caption'} component={'div'} align={'center'}>
			Version {version}
		</Typography>
	);
};
export const FooterRowDefault = () => {
	return (
		<Box>
			<MuiCSSContainer>
				<TextFooter />
				<Version />
			</MuiCSSContainer>
		</Box>
	);
};
