import { ShopPageBasic } from './ShopPageBasic';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import { TagNavSidebar } from '../components/TagNavigation/TagNavSidebar';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CollapseArea } from '../components/CollapseArea/CollapseArea';

export const useStyles = makeStyles((theme) => ({
	SideBar: {
		width: theme.sizes.sideBarWidth,
	},
}));

export const ShopPageSidebarMenu = ({ theme, children, ...pageProps }) => {
	const classes = useStyles({ theme });
	return (
		<ShopPageBasic {...pageProps}>
			<Grid container direction={'row'} spacing={1}>
				<Hidden smDown initialWidth={'md'}>
					<Grid item className={clsx(classes.SideBar)}>
						<TagNavSidebar />
					</Grid>
				</Hidden>
				<Grid item xs>
					{children}
				</Grid>
			</Grid>
		</ShopPageBasic>
	);
};
