import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Divider, Typography } from '@material-ui/core';
// noinspection ES6CheckImport
import { MuiAppContext, MuiForm, MuiFormField } from '@lsoft/mui';
import Grid from '@material-ui/core/Grid';
import { getTranslation, Tr } from '@lsoft/translate';
import PropTypes from 'prop-types';
import { VerifyPinViewForm } from './VerifyPinView.form';
import {
	trResend,
	trResendEmail,
	trVerifyYourAccountByPin,
	trYouDidntReceiveOurEmail,
	trYourVerificationCodeIsInvalid,
} from './VerifyPinView.tr';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { FormError } from '../FormError/FormError';
import Button from '@material-ui/core/Button';
import { RegisterViewContext } from '../Register/RegisterViewContext';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { trSomethingWentWrongPleaseTryAgainLater } from '../CustomerCard/CustomerCardView.tr';
import { shop_customer_contact_request_verification_resend } from '@lsoft/shared/backend/shop/shop';

export const VerifyErrorMapping = {
	InvalidVerificationCode: trYourVerificationCodeIsInvalid,
};

export const VerifyPinView = ({ username, href }) => {
	const { locale, push } = useRouter();
	// noinspection JSCheckFunctionSignatures
	const registerVieContext = useContext(RegisterViewContext);
	const throwSnackbar = useShopSnackbar();

	useEffect(() => {
		if (!_.isNil(registerVieContext)) {
			if (_.isNil(username)) {
				registerVieContext.setCurrentStep('sign_up');
			}
		}
	}, [username, registerVieContext]);

	const { loginInfo, verifyAndReplaceSessionAndSetCookie } =
		useContext(MuiAppContext);
	const setRenderLogin = _.get(loginInfo, 'setRenderLogin', () => {});

	const [error, setErrorState] = useState(null);

	const handleOnValidChange = useCallback(
		async (PIN_CODE) => {
			try {
				await verifyAndReplaceSessionAndSetCookie({
					EMAIL: username,
					ACCOUNT_VERIFICATION_CODE: PIN_CODE,
				});
				// noinspection JSCheckFunctionSignatures
				setRenderLogin(false);
				await push(href);
			} catch (e) {
				const errorClass = _.get(e, 'ERROR_CLASS', null);
				if (_.has(VerifyErrorMapping, errorClass)) {
					setErrorState(errorClass);
				} else {
					throwSnackbar({
						exception: e,
						msg: getTranslation(
							trSomethingWentWrongPleaseTryAgainLater,
							locale
						),
						variant: 'error',
					});
					setErrorState('UnknownError');
				}
			}
		},
		[username]
	);

	const handleResend = useCallback(async () => {
		try {
			await shop_customer_contact_request_verification_resend({
				email: username,
			});
			throwSnackbar({
				msg: getTranslation(trResendEmail, locale),
				variant: 'success',
			});
		} catch (e) {
			// #164 - Verify Pin - If session ACCOUNT_VERIFIED is false but DB is true
			throwSnackbar({
				exception: e,
				msg: getTranslation(trSomethingWentWrongPleaseTryAgainLater, locale),
				variant: 'error',
			});
		}
	}, [username]);
	return (
		<MuiForm {...VerifyPinViewForm({}, handleOnValidChange)}>
			<Grid container spacing={2} justifyContent={'center'}>
				<Grid item xs={12}>
					<Typography>
						<Tr tr={trVerifyYourAccountByPin} />
					</Typography>
				</Grid>
				<Grid item xs={12} container direction={'row'} justifyContent={'center'}>
					<Grid item>
						<MuiFormField name={'PIN_CODE'} />
					</Grid>
				</Grid>
				{!_.isNil(error) && (
					<Grid item xs={12}>
						<FormError errorTr={VerifyErrorMapping[error]} />
					</Grid>
				)}
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Typography align={'center'}>
						<Tr tr={trYouDidntReceiveOurEmail} />
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Button
						onClick={() => handleResend()}
						color={'primary'}
						variant={'contained'}
						fullWidth
					>
						<Tr tr={trResend} />
					</Button>
				</Grid>
			</Grid>
		</MuiForm>
	);
};

VerifyPinView.propTypes = {
	username: PropTypes.string.isRequired,
	href: PropTypes.string,
};

VerifyPinView.defaultProps = {
	href: '/',
};
