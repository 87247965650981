import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Tr } from '@lsoft/translate';

import { MailingListSubscription } from '../../container/shop/MailingListSubscription';
import { MuiForm, MuiFormContext, MuiFormField } from '@lsoft/mui';
import { trContinue } from '../Footer/Footer.tr';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { MailingListSubscriptionByEmailAddressForm } from './MailingListSubscriptionByEmailAddressForm';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		minWidth: '100%',
		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none',
			},
		},
		'& .MuiFormHelperText-contained': {
			marginTop: 0,
			position: 'absolute',
			bottom: -20,
		},
		'& .MuiFormControl-root': {
			minWidth: '100%',
		},
	},
}));

const MailingListSubscriptionByEmailAddress = () => {
	const classes = useStyles();
	const locale = _.get(useRouter(), 'locale', 'en');

	return (
		<Box className={clsx(classes.root)}>
			<MuiForm {...MailingListSubscriptionByEmailAddressForm()}>
				<MuiFormContext.Consumer>
					{({ valid, formData }) => {
						return (
							<Grid container spacing={1} justifyContent={'space-between'}>
								<Grid item xs={8}>
									<MuiFormField
										name={'EMAIL'}
										id={'SubscriptionByEmail'}
										fullWidth={false}
										style={{ width: '100%' }}
										inputProps={{
											style: { minWidth: '100%' },
										}}
									/>
								</Grid>
								<Grid item xs={'auto'}>
									<MailingListSubscription.Container optin={true}>
										<MailingListSubscription.Context.Consumer>
											{({ register }) => {
												return (
													<Button
														disabled={!valid}
														onClick={async () => {
															await register(formData.EMAIL, locale);
														}}
														style={{
															paddingTop: 8,
															paddingBottom: 8,
															borderBottomLeftRadius: 0,
															borderTopLeftRadius: 0,
														}}
													>
														<Tr tr={trContinue} />
													</Button>
												);
											}}
										</MailingListSubscription.Context.Consumer>
									</MailingListSubscription.Container>
								</Grid>
							</Grid>
						);
					}}
				</MuiFormContext.Consumer>
			</MuiForm>
		</Box>
	);
};
MailingListSubscriptionByEmailAddress.propTypes = {};
MailingListSubscriptionByEmailAddress.defaultProps = {};

export { MailingListSubscriptionByEmailAddress };
