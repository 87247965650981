export const trConfirmYourAccount = {
	DE: 'Konto bestätigen',
	EN: 'Verify Account',
	FR: 'Vérifiez votre compte',
};

export const trVerifyYourAccountByPin = {
	DE: 'Wir haben Ihnen einen Bestätigungscode an Ihre E-Mail-Adresse gesendet. Bitte geben Sie die sechsstellige Zahl ein um Ihren Account zu bestätigen.',
	EN: 'We have sent a confirmation code to your e-mail address. Please enter the six-digit number to confirm your account.',
	FR: 'Nous avons envoyé un code de confirmation à votre adresse électronique. Veuillez entrer le numéro à six chiffres pour confirmer votre compte.',
};

export const trYourVerificationCodeIsInvalid = {
	DE: 'Ihr Verifizierungscode ist nicht gültig',
	EN: 'Your verification code is not valid',
	// eslint-disable-next-line quotes
	FR: "Votre code de vérification n'est pas valide",
};

export const trYouDidntReceiveOurEmail = {
	DE: 'Sie haben unsere E-Mail nicht erhalten?',
	EN: 'You have not received our e-mail?',
	// prettier-ignore
	FR: 'Vous n\'avez pas reçu notre e-mail ?',
};

export const trResend = {
	DE: 'Neuen Code anfordern',
	EN: 'Request a new pin',
	FR: 'Demander un nouveau pin',
};

export const trResendEmail = {
	DE: 'Wir haben Ihnen erneut eine E-Mail gesendet',
	EN: 'We have sent you an email again',
	FR: 'Nous vous avons envoyé un nouveau courriel',
};
