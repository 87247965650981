import { ShopPageBasicHeader } from './ShopPageBasicHeader';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import CardContent from '@material-ui/core/CardContent';
import { Footer } from '../components/Footer/Footer';
import { MuiCSSContainer } from '../components/MuiCSSContainer';
import PropTypes from 'prop-types';
import { ScrollToTopButton } from '../components/ScrollToTopButton';

export const useStyles = makeStyles((theme) => ({
	PageContentCard: {
		background: '#fafafa',
	},
	noPadding: {
		'& >.MuiCardContent-root': {
			padding: 0,
		},
	},
	SideBar: {
		width: theme.sizes.sideBarWidth,
	},
}));

export const PageCard = ({ theme, children, noPadding, ...props }) => {
	const classes = useStyles({ theme });
	return (
		<Card
			square
			className={clsx(classes.PageContentCard, {
				[classes.noPadding]: noPadding,
			})}
			{...props}
		>
			<CardContent>{children}</CardContent>
		</Card>
	);
};
PageCard.propTypes = {
	noPadding: PropTypes.bool,
};

export const ShopPageBasic = ({ children, noPadding, ...pageProps }) => {
	return (
		<ShopPageBasicHeader {...pageProps}>
			<MuiCSSContainer style={{ position: 'relative' }}>
				<PageCard noPadding={noPadding}>{children}</PageCard>
				<ScrollToTopButton />
			</MuiCSSContainer>
			<Footer />
		</ShopPageBasicHeader>
	);
};
ShopPageBasic.propTypes = {
	noPadding: PropTypes.bool,
};
