import React from 'react';
import {
	Box,
	Button,
	Divider,
	Hidden,
	List,
	ListItem,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import { MuiCSSContainer } from '../MuiCSSContainer';
import { FooterMenuList } from './FooterMenuList';
import {
	FooterMenuAboutUs,
	FooterMenuService,
	FooterMenuSupport,
} from '../../constants/FooterMenuEntries';
import ListSubheader from '@material-ui/core/ListSubheader';
import { MuiLink } from '@lsoft/mui';
import ExploreIcon from '@material-ui/icons/Explore';
import {
	trAllDataIsTransmittedEncrypted,
	trAllPricesInclVatCrossedOutPrices,
	trFindUs,
	trGoogleMapsRoute,
	trPartnerAndMore,
	trPaySecurelyAndConveniently,
	trPricingInformation,
	trSecureShopping,
} from './Footer.tr';
import { StoreAddress } from '../Contact/StoreAddress';
import { MuiDivider } from '../MuiDivider';
import { NextAssetImage } from '@lsoft/shared/components/ui/NextAssetImage';

const useStyles = makeStyles((theme) => ({
	root: {
		background: grey[500],
	},
	CardHeader: {
		paddingBottom: 0,
	},
	ServiceItem: {
		'& .MuiSvgIcon-root': {
			display: 'block',
			margin: [[0, 'auto', theme.spacing(1), 'auto']],
		},
	},
}));

const FindUs = () => {
	return (
		<List
			subheader={
				<ListSubheader disableSticky>
					<Tr tr={trFindUs} />
				</ListSubheader>
			}
		>
			<ListItem>
				<Box>
					<StoreAddress hidePhoneNumber />
					<MuiDivider />
					<MuiLink
						href={'https://goo.gl/maps/HeYw1KaXMz45anrg9'}
						linkprops={{ style: { color: 'black' }, target: '_blank' }}
					>
						<Button
							color={'default'}
							variant={'outlined'}
							startIcon={<ExploreIcon />}
						>
							<Tr tr={trGoogleMapsRoute} />
						</Button>
					</MuiLink>
				</Box>
			</ListItem>
		</List>
	);
};

const SecureShopping = () => {
	return (
		<List
			subheader={
				<ListSubheader disableSticky>
					<Tr tr={trSecureShopping} />
				</ListSubheader>
			}
			dense
		>
			<ListItem>
				<Box>
					<Typography variant={'caption'} component={'p'} gutterBottom>
						<Tr tr={trAllDataIsTransmittedEncrypted} />
					</Typography>
					<Typography variant={'caption'} component={'p'}>
						<Tr tr={trPaySecurelyAndConveniently} />
					</Typography>
				</Box>
			</ListItem>
		</List>
	);
};

const PriceHint = () => {
	return (
		<List
			subheader={
				<ListSubheader disableSticky>
					<Tr tr={trPricingInformation} />
				</ListSubheader>
			}
			dense
		>
			<ListItem>
				<Box>
					<Typography variant={'caption'} component={'p'}>
						<Tr tr={trAllPricesInclVatCrossedOutPrices} />
					</Typography>
				</Box>
			</ListItem>
		</List>
	);
};

const Partners = () => {
	const electronic_shop_logo_asset = {
		asset_type: 'LINK_PICTURE',
		url: '/images/icons/partner_icons/partner_electronic-shop.png',
		url_type: 'RELATIVE',
		caption: {},
		active: true,
	};
	const vedes_logo_asset = {
		asset_type: 'LINK_PICTURE',
		url: '/images/icons/partner_icons/partner_vedes.png',
		url_type: 'RELATIVE',
		caption: {},
		active: true,
	};
	const letzshop_logo_asset = {
		asset_type: 'LINK_PICTURE',
		url: '/images/icons/partner_icons/ltoys_letzshop.png',
		url_type: 'RELATIVE',
		caption: {},
		active: true,
	};

	return (
		<List
			subheader={
				<ListSubheader disableSticky>
					<Tr tr={trPartnerAndMore} />
				</ListSubheader>
			}
			dense
		>
			<ListItem>
				<Grid container direction={'row'} spacing={0}>
					<Grid item md={3}>
						<MuiLink
							href={'https://www.electronic-shop.lu'}
							target={'blank'}
							fullWidth
						>
							<NextAssetImage
								asset={electronic_shop_logo_asset}
								alt={'Visit electronic-shop.lu'}
								height={150}
								width={100}
							/>
						</MuiLink>
					</Grid>
					<Grid item md={3}>
						<NextAssetImage
							asset={vedes_logo_asset}
							height={150}
							width={100}
							alt={'We are an official VEDES Partner'}
						/>
					</Grid>
					<Grid item md={6}>
						<MuiLink
							href={'https://letzshop.lu/de/vendors/ltoys'}
							target={'blank'}
							fullWidth
						>
							<NextAssetImage
								alt={'Visit our shop profile at letzshop.lu'}
								asset={letzshop_logo_asset}
								height={300}
								width={100}
							/>
						</MuiLink>
					</Grid>
				</Grid>
			</ListItem>
		</List>
	);
};

const ResponsiveDivider = () => {
	return (
		<Hidden initialWidth={'xs'} smUp>
			<Divider />
		</Hidden>
	);
};

const FooterRowMenu = () => {
	const classes = useStyles();

	return (
		<Box className={clsx(classes.root)}>
			<MuiCSSContainer>
				<Grid container>
					<Grid item xs={12} sm={4} md={3}>
						<FooterMenuList list={FooterMenuService} />
						<Divider />
						<FindUs />
						<ResponsiveDivider />
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<FooterMenuList list={FooterMenuAboutUs} />

						<ResponsiveDivider />
					</Grid>
					<Grid item xs={12} sm={4} md={3}>
						<FooterMenuList list={FooterMenuSupport} />
						<ResponsiveDivider />
					</Grid>
					<Grid item xs={12} sm={12} md={3}>
						<PriceHint />
						<Divider />
						<SecureShopping />
						<Divider />
						<Partners />
					</Grid>
				</Grid>
			</MuiCSSContainer>
		</Box>
	);
};

export { FooterRowMenu };
