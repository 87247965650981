export const VerifyPinViewForm = (context, handleOnValidChange) => {
	return {
		onChangeIfValid: (formData) => {
			handleOnValidChange(formData.PIN_CODE);
		},
		fieldGlobals: {
			inputProps: {
				fullWidth: true,
				size: 'small',
				styleVariant: 'outlined',
				shrink: true,
				hideErrorsIfEmpty: true,
				emptyValue: '',
			},
		},
		fields: {
			PIN_CODE: {
				variant: 'pin_code',
			},
		},
	};
};
