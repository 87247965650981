import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Fab, Fade, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { MuiAppContext } from '@lsoft/mui';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2),
		zIndex: theme.zIndexes.HeaderIconPortal - 10,
	},
}));

export const ScrollToTopButton = () => {
	const classes = useStyles();
	const { scrollTop, scrollRef } = useContext(MuiAppContext);
	const [btnVisible, setBtnVisible] = useState(false);

	const handleScroll = () => {
		const clientHeight = scrollRef.current.clientHeight;
		const scrollDistance = scrollRef.current.scrollTop;

		if (scrollDistance > clientHeight / 3) {
			setBtnVisible(true);
		} else {
			setBtnVisible(false);
		}
	};

	useEffect(() => {
		if (!_.isNil(scrollRef)) {
			const scrollRefCurrent = _.get(scrollRef, 'current', null);
			if (!_.isNil(scrollRefCurrent)) {
				scrollRefCurrent.addEventListener('scroll', handleScroll);
				return () => {
					scrollRefCurrent.removeEventListener('scroll', handleScroll);
				};
			}
		}
	}, [scrollRef]);

	if (!btnVisible) return null;

	return (
		<Fade in={btnVisible}>
			<Fab
				color={'primary'}
				onClick={() => scrollTop()}
				className={clsx(classes.root)}
			>
				<KeyboardArrowUpIcon />
			</Fab>
		</Fade>
	);
};

ScrollToTopButton.propTypes = {};
