import React from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	TransparentCard: {
		'&.MuiPaper-root': {
			backgroundColor: 'inherit',
			boxShadow: 'unset',
		},
	},
	noPadding: {
		padding: 0,
		margin: 0,
		'& >.MuiCardHeader-root': {
			paddingTop: 0,
			paddingLeft: 0,
			paddingRight: 0,
		},
		'& >.MuiCardContent-root': {
			paddingTop: 0,
			paddingLeft: 0,
			paddingRight: 0,
			paddingBottom: 0,
		},
	},
	dense: {
		'& >.MuiCardHeader-root': {
			paddingBottom: theme.spacing(1),
		},
	},
}));

const TransparentCard = ({
	children,
	className,
	noPadding,
	dense,
	...props
}) => {
	const classes = useStyles();
	return (
		<Card
			className={clsx(
				classes.TransparentCard,
				{ [classes.noPadding]: noPadding },
				{ [classes.dense]: dense },
				className
			)}
			{...props}
		>
			{children}
		</Card>
	);
};

TransparentCard.propTypes = {
	className: PropTypes.any,
	noPadding: PropTypes.bool,
	dense: PropTypes.bool,
};
TransparentCard.defaultProps = {
	noPadding: false,
	dense: false,
};

export { TransparentCard };
