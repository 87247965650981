export const trFindUs = {
	DE: 'Hier finden Sie uns',
	EN: 'Find Us',
	FR: 'Nous trouver',
};
export const trGoogleMapsRoute = {
	ALL: 'Google Maps Route',
};
export const trSecureShopping = {
	DE: 'Sicher einkaufen',
	EN: 'Secure shopping',
	FR: 'Achats sécurisés',
};
export const trAllDataIsTransmittedEncrypted = {
	DE:
		'Alle Daten die während Ihres Besuches auf ltoys.lu entstehen werden SSL-verschlüsselt übertragen.',
	EN:
		'All data generated during your visit on ltoys.lu is transmitted SSL-encrypted.',
	FR:
		'Toutes les données générées lors de votre visite sur ltoys.lu sont transmises sous forme cryptée par SSL.',
};
export const trPaySecurelyAndConveniently = {
	DE:
		'Bei uns zahlen Sie sicher und bequem. Egal ob online über einen Zahlungsdienstleister oder klassisch per Überweisung.',
	EN:
		'You can pay securely and conveniently at our shop. Whether online via a payment service provider or by bank transfer.',
	FR:
		'Vous pouvez payer de manière sûre et pratique. Que ce soit en ligne via un fournisseur de services de paiement ou par virement bancaire.',
};
export const trAllPricesInclVatCrossedOutPrices = {
	DE:
		'Alle Preise inklusive der gesetzlichen Mehrwertsteuer. Die durchgestrichenen Preise entsprechen dem bisherigen LToys-Preis.',
	EN:
		'All prices incl. VAT. Crossed-out prices correspond to the previous LToys price.',
	// prettier-ignore
	FR: 'Tous les prix s\'entendent TVA comprise. Les prix barrés correspondent au prix précédent de LToys.',
};
export const trPricingInformation = {
	DE: 'Preishinweis',
	EN: 'Pricing information',
	FR: 'Informations sur les prix',
};
export const trPartnerAndMore = {
	DE: 'Partner & mehr',
	EN: 'Partners & More',
	FR: 'Partenaires & plus',
};
export const trSubscribeToOurNewsletter = {
	DE: 'Abonnieren Sie unseren E-Mail-Newsletter!',
	EN: 'Subscribe to our e-mail newsletter!',
	// prettier-ignore
	FR: 'S\'abonner à la newsletter',
};
export const trReceiveInformationProductSales = {
	DE:
		'Erhalten Sie Informationen zu Produktneuheiten, Angeboten und Aktionen direkt und bequem in Ihr Posteingang.',
	EN:
		'Conveniently receive information on new products, offers and promotions directly to your inbox.',
	FR:
		'Recevez facilement des informations sur les nouveaux produits, les offres et les promotions directement dans votre boîte de réception.',
};
export const trEnterYourEmailAddress = {
	DE: 'Geben Sie Ihre E-Mail Adresse ein',
	EN: 'Enter your email address',
	FR: 'Entrez votre adresse e-mail',
};
export const trContinue = { DE: 'Weiter', EN: 'Continue', FR: 'Continuer' };
export const trFollowUs = {
	DE: 'Folgen Sie uns!',
	EN: 'Follow Us',
	FR: 'Suivez-nous !',
};
export const trOurTeamOfExpertsWillHelpYou = {
	DE: 'Unser Expertenteam hilft Ihnen gerne weiter!',
	EN: 'Our team of experts will be happy to help you!',
	// prettier-ignore
	FR: 'Notre équipe d\'experts se fera un plaisir de vous aider !',
};
export const trYouHaveQuestions = {
	DE:
		'Sie haben Frage zu einem unserer Produkte? Sie sind auf der Suche nach einem seltenen Set?',
	EN:
		'You have a question regarding one of our products? You are searching for a rare set?',
	// prettier-ignore
	FR:
		'Vous avez une question concernant l\'un de nos produits ? Vous recherchez un ensemble rare ?',
};
export const trYouNeedHelp = {
	DE: 'Sie benötigen Hilfe? Kontaktieren Sie uns!',
	EN: 'You need support? Contact us!',
	// prettier-ignore
	FR: 'Besoin d\'aide ? Contactez-nous !',
};
export const trSendUsAMessage = {
	DE: 'Nachricht schreiben',
	EN: 'Send us a message',
	FR: 'Envoyez-nous un message',
};
export const trOurServices = {
	DE: 'Unsere Leistungen',
	EN: 'Our Services',
	FR: 'Nos services',
};
export const trCustomerCard = {
	DE: 'Persönliche Kundenkarte',
	EN: 'Customer Card',
	FR: 'Carte client',
};
export const trCustomerCardCollectPoints = {
	DE:
		'Mit der Kundenkarte sammeln Sie bei jedem Kauf in unserem Laden oder in unserem Online-Shop Punkte im Wert von 5% Ihres Einkaufes.',
	EN:
		'With the customer card you collect points worth 5% of your purchase with every transaction in our shop or in our online shop.',
	// prettier-ignore
	FR:
		'Avec la carte client, vous accumulez des points d\'une valeur de 5 % de votre achat à chaque transaction dans notre magasin ou dans notre boutique en ligne.',
};
export const trFreeShipping = {
	DE: 'Kostenloser Versand',
	EN: 'Free Shipping',
	FR: 'Livraison gratuite',
};
export const trOrderValueShippingFree = {
	DE:
		'Für Bestellungen mit einem Bestellwert von 100 € und mehr ist der Standardversand in viele Länder der EU kostenlos.',
	EN:
		'For orders with a value of 100 € and more, standard shipping to many EU countries is free of charge.',
	// prettier-ignore
	FR:
		'Pour les commandes d\'une valeur de 100 € et plus, l\'expédition standard vers de nombreux pays de l\'UE est gratuite.',
};
export const trClickAndCollect = {
	ALL: 'Click & Collect',
};
export const trPlaceOrderOnlineAndCollect = {
	DE:
		'Bestellen Sie bequem online und holen Sie Ihre Ware selbst in unserem Geschäft ab. Sie werden informiert, sobald die Bestellung bereitliegt.',
	EN:
		'Place your order conveniently online and collect your goods in our shop yourself. You will be informed as soon as the order is ready.',
	FR:
		'Passez votre commande en ligne et venez chercher vous-même vos achats dans notre magasin. Vous serez informé dès que la commande sera prête.',
};
