import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Tr } from '@lsoft/translate';
import { trShowLess, trShowMore } from './CollapseArea.tr';

const useStyles = makeStyles((theme) => ({
	root: {},
	toggleArea: {
		position: 'relative',
		height: 58,
		background: '#fafafa',
	},
	toggleAreaOpen: {
		'&:before': {
			position: 'absolute',
			right: 0,
			bottom: 49,
			left: 0,
			height: 40,
			content: '""',
			background:
				'linear-gradient(0, #fafafa 0%, #fafafa 26%, rgba(0,0,0,0) 100%)',
		},
	},
}));

export const CollapseArea = ({ children, collapsedSize }) => {
	const classes = useStyles();
	const [checked, setChecked] = useState(false);
	const handleClick = () => {
		setChecked(!checked);
	};
	return (
		<div className={clsx(classes.root)}>
			<Collapse in={checked} collapsedSize={collapsedSize}>
				{children}
			</Collapse>
			<div
				className={clsx(classes.toggleArea, {
					[classes.toggleAreaOpen]: !checked,
				})}
			>
				<div className={'flex flex-col justify-center gap-2'}>
					<Button
						onClick={handleClick}
						variant={'outlined'}
						className={'mb-16'}
					>
						{!checked ? <Tr tr={trShowMore} /> : <Tr tr={trShowLess} />}
					</Button>
				</div>
			</div>
		</div>
	);
};
CollapseArea.propTypes = {
	collapsedSize: PropTypes.number,
};
CollapseArea.defaultProps = {
	collapsedSize: 300,
};
