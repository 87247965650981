import { useRouter } from 'next/router';
import React, { useCallback, useContext, useState } from 'react';
// noinspection ES6CheckImport
import {
	MuiAppContext,
	MuiForm,
	MuiFormContext,
	MuiFormField,
} from '@lsoft/mui';
import _ from 'lodash';
import { getTranslation, Tr } from '@lsoft/translate';
import {
	trForgotPassword,
	trSignInNow,
	trUsernameOrPasswordWrong,
} from './LoginView.tr';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { LoginViewContext } from './LoginView';
import { SignInViewForm } from './SignInView.form';
import { FormError } from '../FormError/FormError';
import PropTypes from 'prop-types';
import { MuiCookieTooltip } from '../../../libs/mui/MuiCookieTooltip';
// noinspection ES6CheckImport
import { trYouHaveSuccessfullyLoggedIn } from '../Header/Header.tr';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { trSomethingWentWrongPleaseTryAgainLater } from '../CustomerCard/CustomerCardView.tr';
import { shop_customer_contact_request_verification_resend } from '@lsoft/shared/backend/shop/shop';
import { convert_axios_error } from '../../utils/errors';

const md5 = require('md5');

const LoginErrorMapping = {
	UsernameOrPasswordWrong: trUsernameOrPasswordWrong,
};

export const SignInView = ({ href }) => {
	const { locale, push } = useRouter();
	// noinspection JSCheckFunctionSignatures
	const { error, formData, setFormData, setCardMode } =
		useContext(LoginViewContext);
	const throwSnackbar = useShopSnackbar();
	const { loginInfo, login } = useContext(MuiAppContext);
	const setRenderLogin = _.get(loginInfo, 'setRenderLogin', () => {});

	const [errorState, setErrorState] = useState(error);
	const asyncSetErrorState = async (state) => {
		setErrorState(state);
	};

	const handleLogin = useCallback(
		async (formData) => {
			try {
				await asyncSetErrorState(null);

				await login({
					username: formData.USERNAME,
					password: md5(formData.PASSWORD),
				});

				throwSnackbar({
					msg: getTranslation(trYouHaveSuccessfullyLoggedIn, locale),
					variant: 'success',
				});

				if (_.isNil(href)) {
					setRenderLogin(false);
				} else {
					await push(href);
				}
			} catch (e) {
				const error_class = _.get(
					convert_axios_error(e),
					['ERROR_CLASS'],
					'UnknownError'
				);
				if (error_class === 'ContactNotVerified') {
					await shop_customer_contact_request_verification_resend({
						email: formData.USERNAME,
						password: md5(formData.PASSWORD),
					});
					setCardMode('verify_pin');
				} else if (_.has(LoginErrorMapping, error_class)) {
					setErrorState(error_class);
				} else {
					throwSnackbar({
						exception: e,
						msg: getTranslation(
							trSomethingWentWrongPleaseTryAgainLater,
							locale
						),
						variant: 'error',
					});
					setErrorState('UnknownError');
				}
			}
		},
		[login, locale, href, throwSnackbar, setRenderLogin, push, setCardMode]
	);

	const handleOnEnter = (ctxValue, handleLogin, setErrorState) => {
		if (ctxValue.valid) {
			const formData = ctxValue.formData;
			handleLogin(formData);
		} else {
			setErrorState('formNotValidError');
		}
	};

	return (
		<form>
			<MuiForm {...SignInViewForm({ formData, setFormData })}>
				<MuiFormContext.Consumer>
					{(value) => {
						return (
							<Grid container direction={'row'} spacing={2}>
								<Grid item xs={12}>
									<MuiFormField
										name={'USERNAME'}
										onEnter={() => {
											handleOnEnter(value, handleLogin, setErrorState);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<MuiFormField
										name={'PASSWORD'}
										onEnter={() => {
											handleOnEnter(value, handleLogin, setErrorState);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<MuiCookieTooltip>
										<Button
											fullWidth
											variant={'contained'}
											color={'primary'}
											disabled={!value.valid}
											onClick={() => {
												const formData = value.formData;
												// noinspection JSIgnoredPromiseFromCall
												handleLogin(formData);
											}}
										>
											<Tr tr={trSignInNow} />
										</Button>
									</MuiCookieTooltip>
								</Grid>
								<Grid item xs={12}>
									<FormError errorTr={LoginErrorMapping[errorState]} />
								</Grid>
								<Grid
									item
									container
									direction={'row'}
									xs={12}
									justifyContent={'center'}
								>
									<Button onClick={() => setCardMode('password_reset')}>
										<Tr tr={trForgotPassword} />
									</Button>
								</Grid>
							</Grid>
						);
					}}
				</MuiFormContext.Consumer>
			</MuiForm>
		</form>
	);
};

SignInView.propTypes = {
	href: PropTypes.string,
};
SignInView.defaultProps = {
	href: null,
};
