export const MailingListSubscriptionByEmailAddressForm = () => {
	return {
		fieldGlobals: {
			inputProps: {
				fullWidth: true,
				size: 'small',
				styleVariant: 'outlined',
				shrink: true,
				hideErrorsIfEmpty: true,
				emptyValue: '',
			},
		},
		fields: {
			EMAIL: {
				variant: 'email',
				inputProps: {
					noLabel: true,
					placeholder: {
						DE: 'Geben Sie Ihre E-Mail-Adresse ein',
						EN: 'Enter your email address',
						FR: 'Entrez votre adresse e-mail',
					},
				},
			},
		},
	};
};
