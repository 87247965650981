export const trCancelEdit = {
	DE: 'Bearbeiten abbrechen.',
	EN: 'Cancel edit.',
	// prettier-ignore
	FR: 'Annuler l\'édition.',
};
export const trCancelAdd = {
	DE: 'Hinzufügen abbrechen.',
	EN: 'Cancel add.',
	FR: 'Annuler ajouter.',
};

export const trCancel = {
	DE: 'Abbrechen',
	EN: 'Cancel',
	FR: 'Annuler',
};

export const trDoYouWantToCancel = {
	DE: 'Möchten Sie den Vorgang abbrechen?',
	EN: 'Do you want to cancel?',
	FR: 'Vous voulez annuler ?',
};

export const trYouGotUnsavedChanges = {
	DE: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie den Vorgang wirklich abbrechen?',
	EN: 'You got unsaved changes. Are you sure you want to cancel?',
	// prettier-ignore
	FR:	'Vous avez des modifications non sauvegardées. Vous voulez vraiment annuler l\'opération ?',
};

export const addAddress = {
	DE: 'Anschrift hinzufügen',
	EN: 'Add address',
	FR: 'Ajouter une adresse',
};

export const editAddress = {
	DE: 'Anschrift bearbeiten',
	EN: 'Edit address',
	// prettier-ignore
	FR: 'Modifier l\'adresse',
};
export const trSelected = {
	EN: 'Selected',
	DE: 'Ausgewählt',
	FR: 'Sélectionnée',
};
export const trAdd = {
	DE: 'Hinzufügen',
	EN: 'Add',
	FR: 'Ajouter',
};
export const trAddAddress = {
	DE: 'Adresse hinzufügen',
	EN: 'Add address',
	FR: 'Ajouter une adresse',
};
export const trSelect = {
	DE: 'Auswählen',
	EN: 'Select',
	FR: 'Sélectionnez',
};
export const trSave = {
	DE: 'Speichern',
	EN: 'Save',
	FR: 'Enregistrer',
};
export const trNo = { DE: 'Nein', EN: 'No', FR: 'Non' };
export const trYesCancel = {
	DE: 'Ja, abbrechen',
	EN: 'Yes, cancel',
	FR: 'Oui, annuler',
};
export const trDelete = {
	DE: 'Löschen',
	EN: 'Delete',
	FR: 'Supprimer',
};
export const trEdit = { DE: 'Bearbeiten', EN: 'Edit' };
export const trConfirmAddressDelete = {
	DE: 'Sind Sie sicher, dass Sie diese Adresse löschen möchten?',
	EN: 'Are you sure you want to delete this address?',
	FR: 'Êtes-vous sûr de vouloir supprimer cette adresse?',
};
export const trUseAsInvoiceAddress = {
	DE: 'Als Rechnungsadresse verwenden.',
	EN: 'Use as billing address.',
	FR: 'Utiliser comme adresse de facturation.',
};
export const trUseAsDeliveryAddress = {
	DE: 'Als Lieferadresse verwenden.',
	EN: 'Use as delivery address.',
	FR: 'Utiliser comme adresse de livraison.',
};
export const trProfile = { DE: 'Profil', EN: 'Profile', FR: 'Profil' };
export const trYes = {
	DE: 'Ja',
	EN: 'Yes',
	FR: 'Oui',
};
export const trPlsEnterAValidAddress = {
	DE: 'Bitte geben Sie eine gültige Adresse ein',
	EN: 'Please enter a valid address',
	FR: 'Veuillez entrer une adresse valide',
};
export const trVisitProfile = {
	DE: 'Profil besuchen',
	EN: 'Visit profile',
	FR: 'Voir le profil',
};
export const trConfirmVisitProfile = {
	DE: 'Wollen Sie jetzt Ihr Profil besuchen?',
	EN: 'Do you want to visit your profile now?',
	FR: 'Vous souhaitez consulter votre profil maintenant?',
};
export const trUseBillingAddressAsDeliveryAddress = {
	DE: 'Rechnungsanschrift als Lieferanschrift verwenden',
	EN: 'Use billing address as delivery address',
	// prettier-ignore
	FR: 'Utiliser l\'adresse de facturation comme adresse de livraison',
};
export const trCurrentDeliveryAddress = {
	DE: 'Aktuelle Lieferanschrift',
	EN: 'Current delivery address',
	FR: 'Adresse de livraison sélectionnée',
};
export const trChangeDeliveryAddress = {
	DE: 'Lieferanschrift ändern',
	EN: 'Change delivery address',
	// prettier-ignore
	FR: 'Modifier l\'adresse de livraison',
};
export const trSelectDeliveryAddressFromAddressBook = {
	DE: 'Lieferanschrift aus dem Adressbuch auswählen',
	EN: 'Select delivery address from address book',
	// prettier-ignore
	FR: 'Sélectionnez l\'adresse de livraison dans le carnet d\'adresses',
};
export const trUseDeliveryAddressAsBillingAddress = {
	DE: 'Lieferanschrift als Rechnungsanschrift verwenden',
	EN: 'Use delivery address as billing address',
	// prettier-ignore
	FR: 'Utiliser l\'adresse de livraison comme adresse de facturation',
};
export const trCurrentBillingAddress = {
	DE: 'Aktuelle Rechnungsanschrift',
	EN: 'Current billing address',
	FR: 'Adresse de facturation sélectionnée',
};
export const trChangeBillingAddress = {
	DE: 'Rechnungsanschrift ändern',
	EN: 'Change billing address',
	// prettier-ignore
	FR: 'Modifier l\'adresse de facturation',
};
export const trSelectBillingAddressFromAddressBook = {
	DE: 'Rechnungsanschrift aus dem Adressbuch auswählen',
	EN: 'Select billing address from address book',
	// prettier-ignore
	FR: 'Sélectionnez l\'adresse de facturation dans le carnet d\'adresses',
};

export const trPleaseNoteThatParcelMachinesCanNotBeServed = {
	DE: 'Bitte beachten Sie, dass Paketautomaten (wie z. B. die DHL Packstation oder das PackUp-System der POST Luxemburg) derzeit nicht beliefert werden können. Durch eine fehlerhafte Adresseingabe kann es zu Lieferverzögerungen kommen.',
	EN: 'Please note that parcel machines (such as the DHL Packstation or the PackUp system of POST Luxembourg) can not be served at the moment. Delivery delays may occur due to incorrect address entry.',
	// prettier-ignore
	FR: 'Veuillez noter que les machines à colis (telles que la DHL Packstation ou le système PackUp de POST Luxembourg) ne peuvent actuellement pas être livrées. Des retards de livraison peuvent survenir en raison de la saisie d\'une adresse incorrecte.',
};
