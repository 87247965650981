export const trFormNotValidError = {
	DE: 'Bitte füllen Sie das Formular vollständig aus.',
	EN: 'Please fill out the form completely.',
	FR: 'Veuillez remplir le formulaire complètement.',
};
export const trUsernameOrPasswordWrong = {
	DE: 'Passwort und E-Mail-Adresse stimmen nicht überein.',
	EN: 'Provided e-mail address and password do not match.',
	// eslint-disable-next-line quotes
	FR: "Le mot de passe et l'adresse e-mail ne correspondent pas.",
};
export const trContactLoginDisabled = {
	DE: 'Bitte aktivieren Sie Ihr Konto.',
	EN: 'Please verify your account.',
	FR: 'Veuillez vérifier votre compte.',
};
export const trUnknownError = {
	DE: 'Ein unerwarteter Fehler ist aufgetreten.',
	EN: 'An unexpected error has occurred.',
	// eslint-disable-next-line quotes
	// prettier-ignore
	FR: "Une erreur inattendue s\'est produite.",
};
export const trCurrentPasswordWrong = {
	DE: 'Das eingegebene aktuelle Passwort ist falsch.',
	EN: 'The current password entered is incorrect.',
	FR: 'Le mot de passe actuel saisi est incorrect.',
};
export const trLogin = { DE: 'Anmelden', EN: 'Sign-In' };
export const trPasswordReset = {
	DE: 'Passwort zurücksetzen',
	EN: 'Reset Password',
	FR: 'Rétablir le mot de passe',
};
export const trYouWantToRegister = {
	DE: 'Sie möchten sich registrieren?',
	EN: 'Would you like to register?',
	FR: 'Vous souhaitez vous inscrire ?',
};
export const trLoggedInSuccess = {
	DE: 'Anmeldung erfolgreich',
	EN: 'Login successful',
	FR: 'Login réussi',
};
export const trSignInNow = {
	DE: 'Jetzt anmelden',
	EN: 'Sign in now',
	// prettier-ignore
	FR: 'S\'inscrire maintenant',
};
export const trForgotPassword = {
	DE: 'Passwort vergessen?',
	EN: 'Forgot your password?',
	FR: 'Mot de passe oublié ?',
};
export const trPasswordResetNote = {
	DE:
		'Nach Absenden dieses Formulars erhalten Sie eine E-Mail mit den weiteren Schritten um Ihr Passwort zurückzusetzen.',
	EN:
		'After submitting this form you will receive an e-mail with the further steps to reset your password.',
	FR:
		'Après avoir envoyé ce formulaire, vous recevrez un e-mail avec les étapes suivantes pour réinitialiser votre mot de passe.',
};
export const trBackToSignIn = {
	DE: 'Zurück zum Login',
	EN: 'Back to Sign-In',
	FR: 'Retour au login',
};
export const trContinueShopping = {
	DE: 'Weiter einkaufen',
	EN: 'Continue shopping',
	FR: 'Continuer les achats',
};
export const trCreateNewAccount = {
	DE: 'Erstellen Sie ein neues Konto',
	EN: 'Create a new account',
	FR: 'Créer un nouveau compte',
};
